import {
  App,
  Dropdown,
  Flex,
  Layout,
  MenuProps,
  Typography,
  Avatar,
  Modal,
} from "antd";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { useCustomToken } from "src/hooks/useCustomTheme.ts";
import { getUser, logoutUser } from "src/utils/user.ts";

import Intercom, { shutdown } from "@intercom/messenger-js-sdk";
import { getTitle } from "../../utils.ts";
import { Routes } from "src/type.ts";

export function LayoutHeader() {
  const { notification } = App.useApp();
  const state = useRouterState();
  const navigate = useNavigate();
  const user = getUser();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    Intercom({
      app_id: "ilkbhv9c",
      user_id: user.id,
      name:
        user.first_name && user.last_name
          ? `${user.first_name} ${user.last_name}`
          : "",
      email: user.email,
      created_at: Date.parse(user.created_at),
    });
    return () => {
      shutdown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        // Any status code that lies within the range of 2xx causes this function to trigger
        return response;
      },
      (error: AxiosError) => {
        // Any status codes that falls outside the range of 2xx causes this function to trigger
        const { response } = error;
        if (response && response.status === 401) {
          setIsModalOpen(true);
        }
        if (response && response.status >= 500) {
          notification.error({ message: "Something went wrong!" });
        }
        return Promise.reject(error);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    logoutUser();
    void navigate({ to: "/login" });
  };

  const items: MenuProps["items"] = [
    {
      key: "Logout",
      label: <Typography.Text>Logout</Typography.Text>,
    },
  ];

  const onMenuClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "Logout") {
      handleLogout();
    }
  };

  const token = useCustomToken();

  return (
    <Layout.Header
      style={{
        padding: `${token.padding}px ${token.paddingLG}px`,
        background: token.colorBgContainer,
        borderBottom: `1px solid ${token["gray-4"]}`,
      }}
    >
      <Modal
        title="Unauthorized"
        open={isModalOpen}
        onOk={handleLogout}
        footer={(_, { OkBtn }) => <OkBtn />}
      >
        <Typography.Text>Please log in again.</Typography.Text>
      </Modal>
      <Flex justify={"space-between"} align={"center"}>
        <Typography.Title
          level={5}
          style={{ margin: 0, textTransform: "capitalize" }}
        >
          {getTitle(state.location.pathname as Routes)}
        </Typography.Title>

        <Dropdown menu={{ items, onClick: onMenuClick }}>
          <Flex
            gap={token.marginXS}
            align={"center"}
            style={{ cursor: "pointer" }}
          >
            <Avatar>{user.first_name?.[0]}</Avatar>
            <Typography.Text style={{ color: token.colorTextDescription }}>
              {user.first_name} {user.last_name}
            </Typography.Text>
          </Flex>
        </Dropdown>
      </Flex>
    </Layout.Header>
  );
}
