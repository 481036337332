import { CSSProperties } from "react";
import { Flex, Spin } from "antd";
import { useCustomToken } from "src/hooks/useCustomTheme.ts";

type Props = {
  styles?: CSSProperties;
};

export function Spinner({ styles }: Props) {
  const token = useCustomToken();

  return (
    <Flex
      vertical
      justify={"center"}
      align={"center"}
      style={{
        width: "100%",
        height: "100%",
        padding: token.paddingLG,
        ...styles,
      }}
    >
      <Spin />
    </Flex>
  );
}
