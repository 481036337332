import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Config } from "src/routes/dashboard/types.ts";

export type AppState = {
  config: Config | null;
  setConfig: (config: Config | null) => void;
};

const devtoolOptions = {
  name: "Store",
  serialize: {
    options: {
      map: true,
    },
  },
  enable: process.env.NODE_ENV === "development",
};

const initialState = {
  config: null,
};

export const useAppStore = create<AppState>()(
  devtools(
    (set) => ({
      ...initialState,
      setConfig: (config) =>
        set((state) => ({
          ...state,
          config,
        })),
    }),
    devtoolOptions,
  ),
);
