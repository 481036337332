import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const validateSearch = z.object({
  country_code: z.string().optional(),
  country_bbox: z
    .tuple([z.number(), z.number(), z.number(), z.number()])
    .optional(),
});

export const Route = createFileRoute("/dashboard/_layout/places/map-view")({
  validateSearch,
});
