import { App } from "antd";
import axios from "axios";
import { createFileRoute, Outlet, useNavigate } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";
import { logoutUser } from "src/utils/user.ts";
import { Spinner } from "src/components/Spinner.tsx";
import { useAppStore } from "./store.ts";
import { Config } from "./types.ts";

export const Route = createFileRoute("/dashboard")({
  component: Dashboard,
});

function Dashboard() {
  const navigate = useNavigate();
  const { notification } = App.useApp();

  const { isPending } = useQuery({
    queryKey: ["config"],
    queryFn: async () => {
      const url = `/config/private`;
      const response = await axios.get<Config>(url);

      if (!response.data) {
        notification.error({ message: "Something went wrong!" });
        logoutUser();
        void navigate({ to: "/login" });
        return null;
      }
      useAppStore.getState().setConfig(response.data ?? null);
      void navigate({
        to: response.data?.products?.places
          ? "/dashboard/places/map-view"
          : response.data?.products?.geopersona
            ? "/dashboard/geopersona/map-view"
            : "/dashboard/$404",
      });
      return response.data;
    },
    staleTime: Infinity,
  });

  if (isPending) return <Spinner styles={{ minHeight: "100vh" }} />;

  return <Outlet />;
}
